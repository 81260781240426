import React, { useState, useEffect, useCallback } from 'react'
import listJson from '../data/list.json'
import nrhJson from '../data/nrh.json'
import testJson from '../data/test.json'
import huanJson from '../data/huanle.json'
import lifeJson from '../data/life.json'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const Wrapper = styled.section`
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items:center;
    background: url("/images/2014-04-102.jpg") no-repeat; 
    background-size:cover;
    width:100%;
    height:100vh;
`

const Text = styled.p`
    font-size:1.0rem;
    color:#2b5a67;
    font-weight:500;

`

const Input = styled.input`
   border:none;
   background:transparent;
    border-bottom: 2px solid red;
    text-align:center;
    padding:0.5rem;
    width:60%;
    color:#fff;
    font-size:1.2rem;
    outline:none;
`

const Button = styled.button`
    color:rgb(255, 239, 213);
    background: rgb(219, 112, 147);
    border:1px solid transparent;
    padding:0.5rem 1.2rem; 
    font-size:1.0rem;
    margin:1rem;
    //box-shadow: 1rem 1rem 5rem 1rem #ccc;

    &:hover{
        background: #ff4d94;
    }
    `

function Login({ setIsLogin, setListData }) {
    //console.log("state", checkLogin);

    const [inputValue, setInputValue] = useState("2020")

    const handleChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleClick = useCallback(() => {
        const input = btoa(unescape(encodeURIComponent(inputValue)));
        // console.log("clicked", inputValue);
        // checkLogin(parseInt(inputValue, 10))
        switch (input) {
            case process.env.REACT_APP_SECRET_CODE_1:   //"MjAyMA==":
                setIsLogin(true);
                setListData(listJson)
                break;
            case "bnJo":
                setIsLogin(true);
                setListData(nrhJson)
                break;
            case "dGVzdA==":
                setIsLogin(true);
                setListData(testJson)
                break;
            case "5aSn5a6d":
                setIsLogin(true);
                setListData(testJson)
                break;
            case "5bCP5a6d":
                setIsLogin(true);
                setListData(testJson)
                break;
            case "5bm456aP5LmL5a62":  //家
                setIsLogin(true);
                setListData(testJson)
                break;
            case "5qyi5LmQ":  //乐   
                setIsLogin(true);
                setListData(huanJson)
                break;
            case "bnJoIGxpZmU=":  //nrh
                setIsLogin(true);
                setListData(lifeJson)
                break;
            default:
                console.log("Error, the code is incorrect");

        }
    }, [inputValue, setListData, setIsLogin])



    useEffect(() => {
        const listener = (e) => {
            if (e.key === "Enter") {
                //console.log("handle click works");
                handleClick()
            }
        }
        document.addEventListener("keydown", listener)
        return () => {
            document.removeEventListener("keydown", listener)
        }

    }, [handleClick])

    return (
        <Wrapper>
            <Text>Please enter the code 请输入密码 </Text>
            <Input type="text" value={inputValue} onChange={handleChange} />
            <Button onClick={handleClick}>OK</Button>
        </Wrapper>
    )
}

Login.propTypes = {
    setIsLogin: PropTypes.func,
    setListData: PropTypes.func,
}

export default Login
