import React, { forwardRef, useState, useEffect, useRef } from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { SRLWrapper } from 'simple-react-lightbox'
import Img from 'react-cool-img'
import PropTypes from 'prop-types'
import Line from './Line'
import Toggle from './Toggle'
import ScrollTop from './ScrollTop'

const Container = styled.section`
    background: ${props => props.isDarkMode ? '#121212' : 'rgb(255, 239, 213)'} ;
    color: ${props => props.isDarkMode ? 'rgba(227, 227, 227, 0.5)' : 'rgba(239, 0, 0, 0.35)'};
`

const SvgWrapper = styled.div`
    display:flex;
    justify-content:flex-end;
    padding:1rem;  

`

const Svg = styled.svg`
 width:30px;
 height:30px;
 -webkit-tap-highlight-color: transparent;
 position: ${props => props.fixed && 'fixed'};
 bottom: ${props => props.fixed && '1rem'};
 display:${props => props.fixed && props.scrollTopDisplay};


&:hover{
    cursor:pointer;
}
`

const Path = styled.path`
    fill: ${props => props.isDarkMode ? 'rgb(255, 239, 213)' : '#333c'};
    padding:1rem;
  
`

const Wrapper = styled.section`
    display:flex;
    flex-direction: ${props => props.id % 2 === 0 ? 'row-reverse' : 'row'};
    flex-wrap:wrap;
    padding: 4rem;
   
    justify-content:space-around;
    align-items:center;

    @media (max-width:768px){
       padding:3rem 1rem;
    }
    
`
const ImageWrapper = styled.section`
    flex: 0 1 55%;
    text-align: ${props => props.id % 2 === 0 ? 'end' : 'start'};

    @media (max-width:425px){
        flex: 0 1 100%;
    }
`
const Image = styled.img`
    width:auto;
    max-width:100%;
   
    height: 400px;
    background:  #ccffe6;
    object-fit:cover; 

    &:hover{
        cursor:pointer;
    }
`



const imageStyle = {
    width: 'auto',
    maxWidth: '100%',
    height: 400,
    background: '#ccffe6',
    objectFit: 'cover'
}

const TextWrapper = styled.section` 
    flex: 0 1 40%;
    text-align: ${props => props.id % 2 === 0 ? 'start' : 'end'};

    @media (max-width:425px){
        flex: 0 1 100%;
    }
`

const Title = styled.h1`
    font-size: 1.8rem;
    color:rgb(219, 112, 147);
`
const Date = styled.h2`
    font-size:1.3rem;
    color: #ff6600;
`

const Description = styled.p`
    font-size:1.0rem;
    color:#d81764;
    text-align:${props => props.textLength > 100 && 'justify'};  // Set text-align of description to left when there are long sentences.


    &:hover{
        cursor:pointer;
    }

    @media (min-width:125px){
        display: -webkit-box;
        -webkit-line-clamp:12;
        -webkit-box-orient:vertical;
        overflow:hidden; // The code above is to make the ellipsis work with multiple lines of text, except on mobile
    `

const List = forwardRef((props, ref) => {

    // const handleScroll = (e) => {
    //     const scroll = e.target.scrollTop;
    //     console.log("scroll is", scroll);

    //     // setPercent(scroll / e.target.scrollHeight * 100)
    // }
    const [isLineShow, setIsLineShow] = useState(true)
    const [isDarkMode, setIsDarkMode] = useState(false)
    const [scrollTopDisplay, setScrollTopDisplay] = useState("none")
    const [topValue, setTopValue] = useState(10)
    const refElement = useRef();

    const [percent, setPercent] = useState(0)

    useEffect(() => {
        //console.log("lineScroll", lineScroll.current);
        let lastScrollTop = 0;

        const handleScroll = (e) => {
            const topScroll = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset//e.currentTarget.scrollTop;;
            const heightClient = document.documentElement.clientHeight || document.body.clientHeight
            const heightScroll = document.documentElement.scrollHeight || document.body.scrollHeight

            //console.log("scroll is", topScroll);
            const scrollPercent = ((topScroll) / (heightScroll - heightClient) * 100).toFixed()
            //console.log(scrollPercent);

            setPercent(scrollPercent)

            if ((topScroll > lastScrollTop && scrollPercent !== '100') || topScroll === 0) {
                setScrollTopDisplay("none")
            } else {
                setScrollTopDisplay("block")
            }



            lastScrollTop = topScroll;
        }

        document.addEventListener("scroll", handleScroll)

        return () => {
            document.removeEventListener("scroll", handleScroll)
        }
        // const scroll = lineScroll.current.scrollTop;
        // console.log("scroll is", scroll);
        // setPercent(scroll)
    }, [])

    useEffect(() => {
        // console.log("refElement", refElement.current);
        const elements = document.querySelectorAll("p");
        //console.log(Array.isArray(elements));
        [...elements].map((element, index) => {
            if (element.clientHeight < element.scrollHeight) {
                //setIsTextShow(true)
                console.log(index);
            }
            //console.log(element.scrollHeight);

        })


        // if (refElement.current.clientHeight < refElement.current.scrollHeight) {
        //     refElement.current.addEventListener('click', () => {
        //         setIsClickable(true)
        //     })
        // }
    }, [])


    const handleClick = () => {
        //console.log(isLineShow);

        setIsLineShow(!isLineShow)
    }

    // To show the ellipsis description
    const handleDescriptionClick = (e) => {
        e.stopPropagation();  // Avoid impact on percentage line
        //console.log(e.target.classList);
        //e.target.style.color = "green";
        if (e.target.clientHeight < e.target.scrollHeight) {  //check if the text ellipsis works
            //setIsTextShow(true)
            e.target.style['-webkit-line-clamp'] = 100;
        }


    }

    // const handleDoubleClick = () => {
    //     setIsDarkMode(!isDarkMode)
    // }

    const handleDarkModeToggle = (e) => {
        e.stopPropagation();
        setIsDarkMode(!isDarkMode)
    }

    const handleClickToTop = (e) => {
        e.stopPropagation();
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }


    // Below two methods used to make the hr line draggable, seems doesn't work on mobile
    const handleDrop = (e) => {
        //console.log("offsetY is", e.clientY);
        const result = (e.clientY / document.documentElement.clientHeight) * 100;
        //console.log("result is", result);

        setTopValue(result)
    }
    const allowDrop = (e) => {
        e.preventDefault();
    }


    // const handleScroll = () => {
    //     let lastScrollTop = 0;
    //     const currentScrollTop = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
    //     console.log(currentScrollTop);

    //     if (currentScrollTop > lastScrollTop) {
    //         setScrollTopDisplay("none")
    //     } else {
    //         setScrollTopDisplay("block")
    //     }

    //     lastScrollTop = currentScrollTop;
    // }




    return (
        <Container
            //ref={ref}
            ref={refElement}
            onClick={handleClick}
            //onDoubleClick={handleDoubleClick}
            isDarkMode={isDarkMode}
            onDrop={handleDrop}
            onDragOver={allowDrop}

        >
            {/* <Toggle handleToggle={handleDarkModeToggle} /> */}
            {/* <button onClick={handleDarkModeToggle}>To</button> */}
            <SvgWrapper>
                <Svg onClick={handleDarkModeToggle} viewBox="0 0 1024 1024" >
                    <Path isDarkMode={isDarkMode} d="M566.422 1024c157.924 0 302.158-71.85 397.714-189.584 14.136-17.416-1.278-42.86-23.124-38.7-248.406 47.308-476.524-143.152-476.524-393.908 0-144.444 77.324-277.27 202.996-348.788 19.372-11.024 14.5-40.394-7.512-44.46A516.312 516.312 0 0 0 566.422 0c-282.618 0-512 229.022-512 512 0 282.618 229.022 512 512 512z" p-id="1482"></Path>
                </Svg>
            </SvgWrapper>

            <SRLWrapper>

                {/* Conditional statement below to ensure the side effect of setting data complete in the useEffect function of Login component  */}
                {!props.listData ? "Loading..." : props.listData.map((item, index) => (
                    <Wrapper key={item.id} id={item.id}  >
                        <ImageWrapper id={item.id} >

                            {/* <Img src={item.image} lazy={false} style={imageStyle} alt={item.description} /> */}
                            <Image src={item.image} loading="lazy" alt={item.description} onClick={(e) => e.stopPropagation()} />

                        </ImageWrapper>
                        <TextWrapper id={item.id} >
                            <Title >
                                {item.title}
                            </Title>
                            <Date >
                                {item.date}
                            </Date>
                            <Description onClick={handleDescriptionClick} textLength={item.description.length} >
                                {item.description}
                            </Description>
                        </TextWrapper>
                    </Wrapper>
                )
                )}

            </SRLWrapper>
            <Line
                isShow={isLineShow}
                // handleShowClick={handleClick} 
                topValue={topValue}
                percent={percent}
            />
            {/* <ScrollTop /> */}
            <SvgWrapper >
                <Svg
                    fixed
                    scrollTopDisplay={scrollTopDisplay}
                    onClick={handleClickToTop}
                    viewBox="0 0 1024 1024"

                >
                    <Path isDarkMode={isDarkMode} d="M467.2 147.2 332.8 281.6c-25.6 25.6-25.6 64 0 89.6 25.6 25.6 64 25.6 89.6 0L448 345.6 448 832c0 38.4 25.6 64 64 64s64-25.6 64-64L576 345.6l25.6 25.6c25.6 25.6 64 25.6 89.6 0 25.6-25.6 25.6-64 0-89.6L556.8 147.2C531.2 121.6 492.8 121.6 467.2 147.2z" p-id="4460"></Path>
                </Svg>
            </SvgWrapper>

        </Container>
    )
})


List.propTypes = {
    listData: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        date: PropTypes.string,
        image: PropTypes.string,
        description: PropTypes.string,
    })
    )
}

export default List
