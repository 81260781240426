import React, { useState, useRef, useEffect } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
    position:fixed;
    top: ${props => props.topValue + 'vh'};
    width:100%;
    text-align:center;

    // @media (max-width:425px){
    //     top: 9vh;
    // }

`

const Hr = styled.div`
    display: ${props => props.isShow ? 'flex' : 'none'};
    flex-basis: 100%;
    align-items: center;
    //color: ${props => parseInt(props.percent, 10) >= 50 ? parseInt(props.percent, 10) < 100 ? 'rgba(213, 0, 239, 0.35)' : 'rgb(191, 0, 239)' : 'rgba(239, 0, 0, 0.35)'};
    //color: rgba(239, 0, 0, 0.35);
    //transform: ${props => props.percent === '100' && 'scale(2)'};
    margin: 0.5rem 0;
    // transition:  color 2s, transform 2s;
    animation: ${props => ['100', '50'].includes(props.percent) && 'scaleUp 3s'};

    &:hover{
        cursor:all-scroll;
    }
    @keyframes scaleUp{
        0% {transform: scale(1); color:rgba(213, 0, 239, 0.35);}
        50% {transform: scale(2); color:rgb(191, 0, 239);}
        100% {transform: scale(1); color:rgba(213, 0, 239, 0.35);}
}

&:before, &:after {
    content: "";
    flex-grow: 1;
    //color: rgba(239, 0, 0, 0.35);
    height: 1px;
    border-style: none none dotted none;
    font-size: 0;
    line-height: 0;
    margin: 0 0.5rem;
}
`

function Line({ isShow, topValue, percent }) {


    //const lineScroll = useRef()
    //const [isShow, setIsShow] = useState(true)



    //const handleClick = (e) => {
    // e.stopPropagation();
    //}

    //const handleScroll = (e) => {
    //const scroll = e.target.scrollTop;
    // console.log("scroll is", scroll);

    // setPercent(scroll / e.target.scrollHeight * 100)
    //}

    return (
        <Wrapper
            //onClick={handleClick}
            // onScroll={handleScroll}
            //ref={lineScroll}
            draggable="true"
            topValue={topValue}
        >
            <Hr isShow={isShow} percent={percent} > {percent} % </Hr >
        </Wrapper>
    )
}

export default Line
