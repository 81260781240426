import React from 'react'
import styled from 'styled-components';


const Button = styled.button`
    color:#fff;
    background:#0006;
    border:none;
    border-radius:50%;
    outline:none;
    position:fixed;
    bottom:1rem;
    right:1rem;
    padding:.5rem;

`

function ScrollTop() {

    const handleClick = () => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
    }

    return (
        <div>
            <Button onClick={handleClick}>Top</Button>
            <svg viewBox="0 0 1024 1024" width="200" height="200"><path d="M467.2 147.2 332.8 281.6c-25.6 25.6-25.6 64 0 89.6 25.6 25.6 64 25.6 89.6 0L448 345.6 448 832c0 38.4 25.6 64 64 64s64-25.6 64-64L576 345.6l25.6 25.6c25.6 25.6 64 25.6 89.6 0 25.6-25.6 25.6-64 0-89.6L556.8 147.2C531.2 121.6 492.8 121.6 467.2 147.2z" p-id="4460"></path></svg>
        </div>
    )
}

export default ScrollTop
