import React, { useRef, useEffect, useState } from 'react';
import List from './components/List'
import './App.css';
import Login from './components/Login';
import SimpleReactLightbox from 'simple-react-lightbox'
import Line from './components/Line';

function App() {

  const topDistance = useRef()
  const [password, setPassword] = useState()
  const [isLogin, setIsLogin] = useState(false)
  const [listData, setListData] = useState()

  //console.log("is login ", isLogin);

  const props = {
    setIsLogin: setIsLogin,
    setListData: setListData
  }
  useEffect(() => {
    //console.log("distance is", topDistance.current.getBoundingClientRect());
  }, [isLogin])



  return (
    <div className="App" >
      {
        isLogin ?
          <SimpleReactLightbox>
            <List ref={topDistance} listData={listData} />
            {/* <Line /> */}
          </SimpleReactLightbox>
          : <Login {...props} />
      }

    </div>
  );
}

export default App;
